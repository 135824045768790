<template>
  <div v-click-outside="() => (opened = false)" class="lang-switcher">
    <div class="lang-switcher-select" @click="opened = !opened">
      <img :src="country?.flag" width="22" height="14" />
      {{ $i18n.locale?.toUpperCase() }}
      <div :class="`icon-angle-${opened ? 'up' : 'down'} u-bg-steel-grey hide-sm`" />
    </div>
    <div v-show="opened" class="lang-switcher-options">
      <div
        v-for="language in availableLanguages"
        :key="language.code"
        class="lang-switcher-option"
        @click="switchLocale(language?.code, true)"
      >
        <div class="check-option" :class="{ 'check-option-empty': language?.code !== $i18n.locale }">
          <div v-if="language?.code === $i18n.locale" class="icon-check u-bg-white" />
        </div>
        <a class="lang-switcher-option-name"> {{ language?.name }} - {{ language?.code?.toUpperCase() }} </a>
      </div>
    </div>
  </div>
</template>
<script>
import i18nMx from '@/mixins/i18n-mixin'

export default {
  name: 'LangSwitcher',
  mixins: [i18nMx],
  data() {
    return {
      opened: false,
    }
  },
}
</script>

<style lang="scss">
.lang-switcher {
  border-radius: $spacing-xs;
  cursor: pointer;
  padding: $spacing-xs;
  position: relative;
  height: 45px;
  display: flex;
  z-index: 3;
  @include mqmin($mq-md) {
    margin-left: $spacing-lg;
  }
  @include mqmin($mq-md) {
    background-color: var(--bg-grey);
  }
  @include mq($mq-md) {
    margin-right: $spacing-sm;
  }
  @include mq($mq-sm) {
    margin-right: 0;
    margin-left: 0;
  }
  &-select {
    align-items: center;
    display: flex;
    gap: $spacing-sm;
    margin-left: $spacing-xs;
  }
  &-options {
    background-color: var(--white);
    border-radius: $spacing-xs;
    border: 1px solid var(--light-grey);
    display: flex;
    flex-direction: column;
    gap: $spacing-sm;
    left: 0;
    padding: $spacing-sm;
    position: absolute;
    top: 40px;
    .check-option {
      border-radius: $spacing-lg;
      flex: 1;
      height: 18px;
      max-width: 18px;
      min-width: 18px;
      width: 18px;
      background-color: var(--primary);
      .icon-check {
        height: 15px;
        max-width: 11px;
        min-width: 11px;
        width: 10px;
        margin-left: 4px;
        margin-top: 2px;
        mask-size: contain;
      }
    }
    .check-option-empty {
      background-color: var(--light-grey);
    }
  }
  &-option {
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: $spacing-sm;
    &-name {
      white-space: nowrap;
    }
  }
}
</style>
