<template>
  <div
    class="dev-env-banner"
    :class="[isClub ? 'club-banner-active' : '', onStaging ? 'staging' : 'qa']"
    :style="`--clubBannerHeight: ${clubBannerHeight}px`"
  >
    <span> {{ onStaging ? 'Staging' : 'QA' }} </span>
  </div>
</template>

<script setup>
import { useClubStore } from '@/stores/club'
defineProps({
  onStaging: Boolean,
})
const clubBannerHeight = ref(0)
const isClub = computed(() => {
  const clubStore = useClubStore()
  return clubStore.isClub
})
onMounted(() => {
  clubBannerHeight.value = document.querySelector('.club-banner')?.getBoundingClientRect()?.height || 0
})
onBeforeUnmount(() => {
  clubBannerHeight.value = null
})
</script>

<style lang="scss">
.dev-env-banner {
  position: fixed;
  left: 0;
  top: 0;
  text-align: center;
  z-index: 9999;
  width: 80px;
  border-radius: 0 0 $spacing-xs 0;
  @include mq($mq-sm) {
    left: calc(50% - 40px);
    border-radius: 0 0 $spacing-xs $spacing-xs;
  }

  &.staging {
    background-color: var(--red);
    color: var(--white);
  }
  &.qa {
    background-color: var(--yellow);
    color: var(--black);
  }
  &.club-banner-active {
    top: calc(var(--clubBannerHeight));
    @include mq($mq-sm) {
      top: calc(var(--clubBannerHeight));
    }
  }
}
</style>
